











import { Component, Vue } from 'vue-property-decorator'

import EventScheduleQuestionForm from '@/partials/forms/Events/ScheduleQuestionForm.vue'

@Component({
  components: {
    EventScheduleQuestionForm
  }
})
export default class EventScheduleQuestionCreate extends Vue {

}
